import React from "react";

import {HelmetDatoCms} from "gatsby-source-datocms";

import CookieConsent from '../cookie-consent'

export default props => (
    <>
      <CookieConsent locale={props.locale} visibilityRef={props.cookieConsentRef} />

      <HelmetDatoCms seo={props.seo}>
        <html lang={props.locale} />

        {props.title &&
          <title>{props.title}</title>
        }

        {props.robots &&
          <meta name="robots" content={props.robots} />
        }

        {props.canonical &&
          <link rel="canonical" href={process.env.GATSBY_WEB_URL + props.canonical} />
        }

        {((props.slug || 'not-index') === 'index') && (props.localeLinks || []).map((l, i) => (
            <link key={i} rel="alternate" hrefLang={l.locale} href={process.env.GATSBY_WEB_URL + l.url} />
        ))}

        <meta name="facebook-domain-verification" content="t3ud8i06np258b3sa4pjpejatzn02h" />
      </HelmetDatoCms>
    </>
)
